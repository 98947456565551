body{
    background-color: #f0f2f5;
}

botones a{
    text-decoration: none;
}

botones a:hover{
    cursor: pointer;
}

.order{
    cursor: pointer;
}

div::-webkit-scrollbar-thumb{
    background-color:#4CAF50;
    margin-top: 10px;
    border: 4px solid transparent;
    border-radius: 15px;
    background-clip: padding-box; 
}
div::-webkit-scrollbar{
    width: 14px;
}

.openMenu:hover{
    cursor: pointer;
}