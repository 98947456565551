.contenido{
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.contenidoNav{
    margin-top: 50px;
    align-items: center;
    align-self: center;
    align-content: center;
    margin: 6px;
    width: 95%;
    padding: 5px;
    border-radius: 15px;
    background-color: #f0f2f5;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.espacio{
    position: absolute;
    width: 13%;
    height: 88%;
    overflow-y: scroll;
}

div::-webkit-scrollbar-thumb{
    background-color:#4CAF50;
    margin-top: 10px;
    border: 4px solid transparent;
    border-radius: 15px;
    background-clip: padding-box; 
}
div::-webkit-scrollbar{
    width: 14px;
}

.openMenu:hover{
    cursor: pointer;
}